(function () {
    "use strict";

    angular.module('informaApp')
        .directive('infFilterList', ['$timeout', '$filter', 'WordHighlightHelper', 'debounce', FilterList]);

    function FilterList($timeout, $filter, WordHighlightHelper, debounce) {
        return {
            restrict: 'E',
            templateUrl: 'directives/home/inf-filter/inf-filter-list/template.ptl.html',
            scope: {
                options: '=',
                dependedItemOptions: '=',
                onModeChange: '=',
                onSelectedCountChange: '=',
                onExcludedCountChange: '=',
                highlightingDisabled: '=',
                key: '=',
                highlighting: '=',
                onReady: '=',
                excludingIsAvailable: '=',
                onLoad: '='
            },
            link: function (scope, element) {
                scope.visableItems = [];
                scope.excludeItemsCount = 0;

                scope.exclude = item => {
                    item.excluded = true;
                    scope.excludeItemsCount++;

                    scope.selectItem(item, false);
                    
                    if (!scope.selectedItemsCount) {
                        scope.selectAllItems(true);
                    }

                    emitOnExcludedCountChange();
                };

                scope.include = item => {
                    item.excluded = false;
                    scope.excludeItemsCount--;

                    emitOnExcludedCountChange();
                };

                scope.onScrollDown = function () {
                    var length = scope.visableItems.length;

                    const source = (scope.filteredSource || scope.options.source || []).filter(x => !x.excluded);
                    const filterSource = getFilteredSourceByQuery(source);

                    const itemsToAdd = filterSource ? filterSource.slice(length, length + 50) : [];

                    scope.visableItems.push.apply(scope.visableItems,  itemsToAdd);
                };

                scope.changeQuery = debounce(300, function (e) {
                    scope.query = e.target.value;
                    resetVisibleItems();
                });

                scope.selectAllItems = selectAllItems;
                scope.selectItem = selectItem;
                scope.clickOnSelectCheckbox = clickOnSelectCheckbox;

                scope.highlightItem = highlightItem;

                scope.selectedItemsCount = 0;

                scope.$watch("options", processData);

                scope.$watch('dependedItemOptions', function (options) {
                    if (options) {
                        subscribeCallback(options, 'onSelectItem', onDependedSelectItem);
                        subscribeCallback(options, 'onHighlightItem', onDependedHighlightItem);

                        if (options.source) {
                            scope.activeDependedItem = _.find(options.source, function (x) {
                                return x.highlighted;
                            });
                        }

                        scope.filteredSource = getFilteredSource();

                        refreshTotalChildrenCount();

                        emitOnReady();
                    }
                });

                scope.$watch('options.modeOptions.value', function (newValue, oldValue) {
                    radioChange(newValue);
                });

                scope.$watch('query', function () {
                    setFilteredCount({totalChildrenCount: scope.options.totalCount});
                });

                scope.$watch('filteredSource', function () {
                    markSearchResults();
                });

                scope.radioChange = radioChange;

                scope.$watch('onLoad', () => {
                    if (scope.onLoad) {
                        scope.onLoad({
                            reset: scope.reset
                        });
                    }
                });
                
                scope.reset = reset;

                function reset() {
                    scope.activeItem = null;
                }

                function processData(options) {
                    if (!options) {
                        return;
                    }

                    if (scope.activeDependedItem) {
                        scope.filteredSource = getFilteredSource();
                        options.totalCount = scope.activeDependedItem.totalChildrenCount;
                    }

                    options.selectItem = selectItem;
                    options.refreshParentSelections = refreshParentSelections;
                    options.refreshTotalChildrenCount = refreshTotalChildrenCount;
                    options.render = renderPin;
                    options.highlightItem = scope.highlightingDisabled ? null : highlightItem;

                    if (!options.source) {
                        return;
                    }

                    resetActiveItem();

                    setFilteredCount({totalChildrenCount: options.totalCount})

                    refreshAllSelectedFlag();
                    refreshParentSelections();

                    refreshSelectedItemsCount();
                    refreshExcludedItemsCount();

                    if (scope.dependedItemOptions) {
                        refreshTotalChildrenCount();
                    }

                    emitOnReady();

                    resetVisibleItems();
                }

                function resetActiveItem() {
                    if (!scope.activeItem) {
                        return;
                    }

                    scope.activeItem = _.find(scope.options.source, function (x) {
                        return x.id === scope.activeItem.id;
                    });
                }

                function clickOnSelectCheckbox(e, item) {
                    selectItem(item);

                    if (!scope.highlightingDisabled) {
                        highlightItem(item);
                    }

                    e.stopPropagation();
                }

                function selectItem(item, value, doNotEmit, doNotRefresh) {
                    item.subselected = false;
                    item.selected = value == null
                        ? !item.selected
                        : value;

                    refreshAllSelectedFlag();

                    if (!doNotRefresh) {
                        refreshParentSelections();
                    }

                    if (doNotEmit !== true) {
                        emitOnCallback(scope.options.onSelectItem, item);
                    }

                    refreshSelectedItemsCount();
                }

                function selectAllItems(value) {
                    var isSelected = value == null
                        ? !scope.allSelected
                        : value;


                    _.forEach(scope.filteredSource || scope.options.source, function (x) {
                        if (x.selected !== isSelected) {
                            selectItem(x, isSelected);
                        }
                    });

                    refreshAllSelectedFlag();
                    refreshSelectedItemsCount();
                }

                function highlightItem(item, value) {
                    if (item.excluded) {
                        return;
                    }

                    if (scope.highlightingDisabled) {
                        selectItem(item);
                        return;
                    }

                    if (scope.activeItem == item && (value === null || item.highlighted === value)) {
                        return;
                    }

                    if (scope.activeItem) {
                        scope.activeItem.highlighted = false;
                    }

                    item.highlighted = value == null ?
                        (item.selected || !item.highlighted)
                        : value;

                    if (item.highlighted) {
                        scope.activeItem = item;
                    }

                    emitOnCallback(scope.options.onHighlightItem, item);
                }

                function emitOnCallback(callbacks, data) {
                    if (callbacks) {
                        _.forEach(callbacks, function (x) {
                            x(data);
                        })
                    }
                }

                function subscribeCallback(options, callbacksName, callback) {
                    if (options[callbacksName]) {
                        options[callbacksName].push(callback);
                    } else {
                        options[callbacksName] = [callback];
                    }
                }

                function onDependedSelectItem(item) {
                    selectByParentId(item.id, item.selected);
                }

                function selectByParentId(parentId, selected) {
                    _.forEach(scope.options.source, function (x) {
                        if (x.parentId === parentId) {
                            selectItem(x, selected, null, true);
                        }
                    });
                }

                function onDependedHighlightItem(item) {
                    scope.activeDependedItem = item.highlighted ? item : null;
                    scope.filteredSource = getFilteredSource();

                    setFilteredCount(item);

                    if (scope.activeItem) {
                        highlightItem(scope.activeItem, false);
                        scope.activeItem = null;
                    }

                    refreshAllSelectedFlag();
                }

                function getFilteredSource() {
                    return _.filter(scope.options.source, function (x) {
                        if (scope.dependedItemOptions == null) {
                            return true;
                        }

                        return scope.activeDependedItem != null && scope.activeDependedItem.id === x.parentId;
                    });
                }

                function refreshAllSelectedFlag() {
                    var source = scope.filteredSource || scope.options.source;

                    scope.allSelected = _.every(source, function (x) {
                        return x.selected;
                    });
                }

                function refreshSelectedItemsCount() {
                    var selectedItems = _.filter(scope.options.source, function (x) {
                        return x.selected;
                    });

                    scope.selectedItemsCount = selectedItems.length;

                    if (scope.onSelectedCountChange) {
                        scope.onSelectedCountChange(scope.key)
                    }
                }

                function refreshExcludedItemsCount() {
                    scope.excludeItemsCount = scope.options.source.filter(x => x.excluded).length;

                    emitOnExcludedCountChange();
                }

                function emitOnExcludedCountChange() {
                    if (scope.onExcludedCountChange) {
                        scope.onExcludedCountChange(scope.key);
                    }
                }

                function refreshParentSelections() {
                    if (!scope.dependedItemOptions) {
                        return;
                    }

                    _.forEach(scope.dependedItemOptions.source, function (item) {
                        var counts = getCounts(item.id);

                        scope.dependedItemOptions.selectItem(item, counts.selectedAndSubSelected !== 0, true, true);
                        item.subselected = counts.selectedAndSubSelected > 0 && counts.selectedOnly < counts.all;
                    });

                    scope.dependedItemOptions.refreshParentSelections();
                }

                function getCounts(parentId) {
                    var all = 0,
                        selectedOnly = 0,
                        selectedAndSubSelected = 0;

                    for (var i = 0; i < scope.options.source.length; i++) {
                        var item = scope.options.source[i];

                        if (item.parentId === parentId) {
                            all++;

                            if (item.selected) {
                                selectedAndSubSelected++;

                                if (!item.subselected) {
                                    selectedOnly++;
                                }
                            }
                        }
                    }

                    return {
                        all: all,
                        selectedOnly: selectedOnly,
                        selectedAndSubSelected: selectedAndSubSelected
                    }
                }

                function refreshTotalChildrenCount() {
                    if (!scope.dependedItemOptions) {
                        return null;
                    }

                    _.forEach(scope.dependedItemOptions.source, function (item) {
                        var childrenCount = _.sumBy(scope.options.source, function (x) {
                            var totalNumber = x.totalNumber == null ? 1 : x.totalNumber;

                            return x.parentId === item.id
                                ? totalNumber
                                : 0;
                        });

                        item.totalNumber = childrenCount;
                    });

                    if (scope.dependedItemOptions.refreshTotalChildrenCount) {
                        scope.dependedItemOptions.refreshTotalChildrenCount();
                    }
                }

                function renderPin() {
                    $timeout(function () {
                        updatePin();
                    });
                }

                function updatePin() {
                    var pin = element.find('.pin-bottom');
                    var ul = element.find('ul');

                    if (scope.filteredItemsCount > 0) {
                        updateBorder(ul, pin);

                        if (scope.ulWidth === ul.prop("clientWidth")) {
                            return;
                        }

                        scope.ulWidth = getUlWidth(ul);

                        if (scope.ulWidth > 0) {
                            pin.css('visibility', 'visible');
                            pin.css('width', scope.ulWidth + 'px');
                        } else {
                            pin.css('visibility', 'hidden');
                        }

                    }
                }

                function getUlWidth(ul) {
                    var bordersWidth = parseInt(ul.css('border-left-width')) * 2;

                    return ul[0].scrollWidth - bordersWidth;
                }

                function updateBorder(ul, pin) {
                    if (ul.prop("scrollHeight") == ul.prop('clientHeight') || ul.scrollTop() + ul.innerHeight() >= ul.prop('scrollHeight')) {
                        pin.css('border-top', 'none');
                    } else {
                        pin.css('border-top', '');
                    }
                }

                function setFilteredCount(item) {
                    var searchedSource = getFilteredSourceByQuery(scope.filteredSource || scope.options.source);

                    var itemsCount = (searchedSource || 0) && searchedSource.length;

                    scope.totalCount = item.totalChildrenCount || itemsCount;
                    scope.filteredItemsCount = scope.totalCount - itemsCount;

                    renderPin();
                }

                function getFilteredSourceByQuery(source) {
                    return $filter('filter')(source, {name: scope.query});
                }

                function radioChange(value) {
                    if (scope.onModeChange) {
                        scope.onModeChange(value);
                    }
                }

                function markSearchResults() {
                    $timeout(function () {
                        WordHighlightHelper.highlight(element.find('.inf-selection.selection span.text'), scope.highlighting);
                    });
                }

                function emitOnReady() {
                    if (scope.onReady) {
                        scope.onReady(scope.filteredSource || scope.options.source, scope.key);
                    }
                }

                function resetVisibleItems() {
                    scope.visableItems = [];
                    scope.onScrollDown();
                }

                renderPin();
            }
        }
    }
})();
